import React, { useState, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/Layout';
import Image from '../components/Image';
import SEO from '../components/SEO';
import PodcastCard from '../components/PodcastCard';
import { Podcast } from '../types';
// @ts-ignore
import HeroVideo from '../assets/intro.mp4';
import '../styles/index.scss';

type Data = {
  allContentfulPodcast: {
    edges: {
      node: Podcast;
    }[];
  };
}

const IndexPage = ({ data }: { data: Data }) => {
  const [activePodcastId, setActivePodcastId] = useState('');
  const [showVideo, setShowVideo] = useState(true);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  return (
    <Layout>
      <SEO title="Chase Chewning" />

      <div className="hero">
        {showVideo ? (
          <video
            className="hero__video"
            ref={videoRef}
            autoPlay
            muted
            playsInline
            onEnded={() => setShowVideo(false)}
          >
            <source src={HeroVideo} type="video/mp4" />
          </video>
        ) : (
          <Image className="hero__image" fileName="index-hero.png" />
        )}

        <div className="hero__overlay" />

        <div className="hero__content">
          <div className="wrapper">
            <h1>Coach. Podcaster. COACHCASTER.</h1>

            <p className="hero__blurb">Health coach turned podcaster, Chase interviews industry leaders in fitness nutrition and mindset. Messages to help you live a life EVER FORWARD.</p>

            <h2>Chase Chewning, MS, ACE CHC</h2>

            <div className="actions">
              <a
                href="https://podcasts.apple.com/us/podcast/ever-forward-radio-with-chase-chewning/id1195967486"
                className="button button--blue"
                target="_blank"
                rel="noreferrer noopener"
              >
                Listen on Apple Podcasts
              </a>

              <p>or</p>

              <Link to="/contact" className="button button--white">Contact Me</Link>
            </div>
          </div>
        </div>

        {showVideo ? null : (
          <div className="hero-playback">
            <FontAwesomeIcon icon={faPlay} onClick={() => setShowVideo(true)} />
          </div>
        )}
      </div>

      <section>
        <h2 className="text-center section-header">Recent Episodes</h2>

        <div className="container podcasts-container">
          <div className="grid">
            {data.allContentfulPodcast.edges.map((elem) => (
              <div key={elem.node.id} className="grid__col--xs-12 grid__col--sm-6 grid__col--lg-4">
                <PodcastCard
                  activePodcastId={activePodcastId}
                  podcast={elem.node}
                  onPodcastPlayStateChanged={(id) => setActivePodcastId(id)}
                />
              </div>
            ))}
          </div>

          <div className="text-center">
            <Link to="/podcasts" className="button">View More</Link>
          </div>
        </div>
      </section>

      <section>
        <div className="radio-banner">
          <Image
            alt="Chase Chewning interviewing Rob Lipsett"
            className="radio-banner__image-desktop"
            fileName="index-radio.png"
          />

          <Image className="radio-banner__image-mobile" fileName="index-radio_mobile.png" />

          <div className="radio-banner__overlay" />

          <div className="radio-banner__content text-center">
            <h2 className="section-header">Ever Forward Radio</h2>

            <h3 className="section-sub-header">Listen to exclusive content from industry leaders, best selling authors, influencers, military members and entrepreneurs.</h3>

            <p className="message">Over 3,000,000 downloads and counting! This is the podcast devoted to help you build a life that propels you forward.</p>

            <p className="sub-message">Interested in becoming a sponsor? Each episode contains great exposure for your brand.</p>

            <Link to="/contact" className="button">Learn More about Sponsorship Opportunities</Link>
          </div>
        </div>
      </section>

      <section style={{ padding: '0 15px' }}>
        <h2 className="text-center section-header section-header--blue">About Me</h2>

        <div className="about-container">
          <div className="video-container">
            <div className="iframe-wrapper">
              <iframe
                title="Ever Forward"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/o9eMqu2wAvA"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>

          <div className="text-center text-container">
            <h3 className="section-sub-header">Who is Chase Chewning?</h3>

            {/* eslint-disable-next-line max-len */}
            <p>A Virginia native and Army veteran, Chase is a Virginia Commonwealth University alum, graduating from their Health, Physical Education and Exercise Science undergraduate program in 2013. Having completed his MS in Health Promotion from American University in Washington, DC he also holds the following credentials: ACE Certified Health Coach and TRX Certified Suspension Trainer.</p>

            {/* eslint-disable-next-line max-len */}
            <p>Living a life of wellness has always been a part of him - since growing up eating fresh food from his grandparents&apos; garden, playing baseball throughout school and enjoying time in the mountains surrounding his family&apos;s southwest VA home. After six years of active duty, Chase was medically discharged from the military due a string of injuries that ultimately required him to have bilateral reconstructive hip surgeries. After learning how to walk again, twice, exercise as medicine and healthy lifestyle modifications became his passion.</p>

            <p>Chase currently resides in Los Angeles, California.</p>

            <a
              href="https://instagram.com/chase_chewning"
              className="button"
              target="_blank"
              rel="noreferrer noopener"
            >
              Follow Me on Instagram
            </a>
          </div>
        </div>
      </section>

      <section>
        <div className="vector-container">
          <div>
            <Image className="vector-container__background" fileName="index-footer-vector.png" />

            <div className="vector-container__overflow" />
          </div>

          <div className="top">
            <Image className="vector-container__image-chase" fileName="index-chase.png" />

            <div className="top__content text-center">
              <h2 className="section-header section-header--white">Welcome to Operation Podcast!</h2>

              {/* eslint-disable-next-line max-len */}
              <p>Interested in launching your own podcast? Chase is also the founder of Operation Podcast, the ultimate resource to successfully launch, grow and monetize your podcast.</p>

              {/* eslint-disable-next-line max-len */}
              <p>We also offer a full suite of premier podcast production and consulting services for your show that is looking to be fully optimized to for its maximum outreach and engagement potential and make sure it is in total alignment with your brand, your business, and your story.</p>
            </div>
          </div>

          <div className="bottom text-center">
            <h3 className="section-header section-header--white">Let&apos;s Get Started!</h3>

            {/* eslint-disable-next-line max-len */}
            <p>The content and community at OPERATION PODCAST are waiting for you. We have the experience and the tools necessary to educate and empower you to start the next great podcast - YOURS!</p>

            <a
              href="https://www.operationpodcast.com"
              className="button"
              target="_blank"
              rel="noreferrer noopener"
            >
              Launch your Podcast TODAY!
            </a>
          </div>

          <Image className="vector-container__image-podcast" fileName="index-podcast.png" />
        </div>
      </section>

    </Layout>
  );
};

export const query = graphql`
  query {
    allContentfulPodcast(limit: 6, filter: {node_locale: {eq: "en-US"}}, sort: {fields: [publishedDate], order: DESC}) {
      edges {
        node {
          audioUrl
          id
          primaryImage {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          publishedDate
          slug
          title
        }
      }
    }
  }
`;

export default IndexPage;
