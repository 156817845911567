import React, {
  FunctionComponent,
  useRef,
  useState,
  useEffect,
} from 'react';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

import { Podcast } from '../types';

interface Props {
  activePodcastId: string;
  podcast: Podcast;
  onPodcastPlayStateChanged: (id: string) => void;
}

const PodcastCard: FunctionComponent<Props> = (props: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [seekPosition, setSeekPosition] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState('');

  const { activePodcastId, podcast, onPodcastPlayStateChanged } = props;

  const playerRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    function formatTime(time: number) {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time - (hours * 3600)) / 60);
      const seconds = Math.floor(time - (hours * 3600) - (minutes * 60));

      return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    function onSeek(event: Event) {
      const target = event.target as HTMLAudioElement;

      setSeekPosition((target.currentTime / target.duration) * 100);
      setTimeRemaining(formatTime(target.duration - target.currentTime));
    }

    const player = playerRef.current;

    if (player) {
      player.addEventListener('timeupdate', onSeek);
    }

    return () => {
      if (player) {
        player.removeEventListener('timeupdate', onSeek);
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current && isPlaying && activePodcastId !== podcast.id) {
      playerRef.current.pause();

      setIsPlaying(false);
    }
  }, [activePodcastId, podcast, isPlaying]);

  function formatDate(date: Date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  function onClick() {
    if (playerRef.current) {
      setIsPlaying(!isPlaying);

      if (isPlaying) {
        playerRef.current.pause();

        onPodcastPlayStateChanged('');
      } else {
        playerRef.current.play();

        onPodcastPlayStateChanged(podcast.id);
      }
    }
  }

  return (
    <div className="podcast-card">
      {podcast.primaryImage ? (
        <Link to={`/podcasts/episode/${podcast.slug}`}>
          <Img fluid={podcast.primaryImage.fluid} />
        </Link>
      ) : null}

      <div className="podcast-card__row">
        <div>
          <p className="podcast-card__date">{formatDate(new Date(podcast.publishedDate))}</p>

          <Link to={`/podcasts/episode/${podcast.slug}`}>
            <p className="podcast-card__title">{podcast.title}</p>
          </Link>
        </div>

        {/* eslint-disable jsx-a11y/media-has-caption */}
        {podcast.audioUrl ? (
          <>
            <audio controls style={{ display: 'none' }} ref={playerRef}>
              <source src={podcast.audioUrl} type="audio/mpeg" />
            </audio>

            <button
              type="button"
              className="podcast-card__button"
              onClick={onClick}
            >
              {isPlaying ? (
                <FontAwesomeIcon icon={faPause} title="Pause" />
              ) : (
                <FontAwesomeIcon icon={faPlay} title="Play" />
              )}
            </button>
          </>
        ) : null}
      </div>

      <p className="podcast-card__time-remaining text-right">{`-${timeRemaining}`}</p>

      <div className="podcast-card__seek-track" style={{ width: `${seekPosition}%` }} />
    </div>
  );
};

export default PodcastCard;
